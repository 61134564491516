.blue-white-button-on {
	box-sizing: content-box;
	display: flex;
	align-items: center;
	background-color: #0F2E4B;
	border: 2px solid #0F2E4B;
	color: #FFF;
	font-size: 22px;
	font-weight: 500;
	border-radius: 6px;
	cursor: pointer;
	user-select: none;
	padding: 10px;
}

.blue-white-button {
	box-sizing: content-box;
	display: flex;
	align-items: center;
	background-color: #FFF;
	border: 2px solid #0F2E4B;
	color: #0F2E4B;
	font-size: 22px;
	font-weight: 500;
	border-radius: 6px;
	cursor: pointer;
	user-select: none;
	padding: 10px;
}
