// @import "./../../../../Style/base";
@import "./../../../../Style/form";

.input_wrapper {
  position: relative;
  .input_field_wrapper {
    position: relative;
    svg {
      position: absolute;
      bottom: 25px;
      right: 12px;
    }
    &.error {
      svg {
        bottom: 23px;
      }
    }
    select {
      width: 100%;
    }
    option {
      font-family: "Quicksand", sans-serif;
      color: #828482;
    }
  }
}

.email_error {
  color: #d9212c;
  font-family: "Quicksand", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
}
