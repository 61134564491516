.search-header-top-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px;
	height: 30px;
	color: #FFFFFF;
	font-size: 12px;

	.search-header-top-row-logo-container {
		display: flex;
		align-items: center;
		padding: 5px;
		gap: 15px;
		
	}
	.img{
		width:50px;
		height:50px;
	}

	.search-header-top-row-buttons {
		user-select: none;
		display: flex;
		align-items: center;
		font-size: 12px;
		color: white;
	}

	

	.search-header-top-row-button {
		cursor: pointer;
	}

	.search-header-top-row-button-separator {
		padding: 0 15px;
		font-size: 16px;
	}
}
