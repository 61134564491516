.image-input {
  position: relative;
  display: inline-block;
  border-radius: 0.475rem;
  background-repeat: no-repeat;
  background-size: cover;
}
.image-input:not(.image-input-empty) {
  background-image: none !important;
}
.image-input .image-input-wrapper {
  width: 120px;
  height: 120px;
  border-radius: 0.475rem;
  background-repeat: no-repeat;
  background-size: cover;
}
.image-input [data-mk-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(-50%, -50%);
}
.image-input [data-mk-image-input-action="change"] {
  left: 100%;
  top: 0;
}
.image-input [data-mk-image-input-action="change"] input {
  width: 0 !important;
  height: 0 !important;
  overflow: hidden;
  opacity: 0;
}
.image-input [data-mk-image-input-action="cancel"],
.image-input [data-mk-image-input-action="remove"] {
  position: absolute;
  left: 100%;
  top: 100%;
}
.image-input [data-mk-image-input-action="cancel"] {
  display: none;
}
.image-input.image-input-changed [data-mk-image-input-action="cancel"] {
  display: flex;
}
.image-input.image-input-changed [data-mk-image-input-action="remove"] {
  display: none;
}
.image-input.image-input-empty [data-mk-image-input-action="remove"],
.image-input.image-input-empty [data-mk-image-input-action="cancel"] {
  display: none;
}
.image-input.image-input-circle {
  border-radius: 50%;
}
.image-input.image-input-circle .image-input-wrapper {
  border-radius: 50%;
}
.image-input.image-input-circle [data-mk-image-input-action="change"] {
  left: 100%;
  top: 0;
  transform: translate(-100%, 0%);
}
.image-input.image-input-circle [data-mk-image-input-action="cancel"],
.image-input.image-input-circle [data-mk-image-input-action="remove"] {
  left: 100%;
  top: 100%;
  transform: translate(-100%, -100%);
}
.image-input.image-input-outline .image-input-wrapper {
  border: 3px solid #ffffff;
  box-shadow: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05);
}

.w-125px {
  width: 125px !important;
}

.h-125px {
  height: 125px !important;
}

.w-25px {
  width: 25px !important;
}
.h-25px {
  height: 25px !important;
}
.shadow {
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075) !important;
}

.label-image {
  position: relative;
  display: inline-block;
  border-radius: 0.475rem;
  background-repeat: no-repeat;
  background-size: cover;
}

.label-extra {
  cursor: pointer;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  top: 0%;
}

.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  /* height: calc(1.5em + 1.5rem + 2px); */
  width: calc(1.5em + 1.5rem + 2px);
}

.form-text {
  font-size: 8px;
  font-weight: 500;
  text-align: center;
}
