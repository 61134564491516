.selected-properties-container {
	display: grid;
	grid-template-rows: 255px 1fr;
.link18-bold-no-line {
	font-size: 25px;
	height: 25px;
    font-weight: bold;
    text-decoration: none;
	align-items: center;
}
	.selected-properties-title {
		color: #284866;
		font-size: 35px;
		font-weight: 500;
	}
	.selected-properties-sub-title {
		font-size: 22px;
		font-weight: 500;
		text-align: center;
		padding-bottom: 10px;
	}

	.selected-properties-results {
		position: relative;
		padding: 20px 30px;

		.selected-properties-results-buttons {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.selected-properties-boxes {
			grid-row-gap: 30px;
			width: 100%;
			// display: flex;
			// justify-content: space-between;
		}
	}
}
