.forgot-container {
	display: grid;
	grid-template-columns: 40% 60%;
	height: 100vh;
}

.forgot-pic-left {
	background-image: url("../../assets/desktop/signin-left-image.png");
	background-size: cover;
}

.forgot-main {
	display: grid;
	grid-template-rows: 1fr 50px;
}

.forgot-box-parent {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #FDFDFD;
}

.forgot-box-child {
	box-sizing: border-box;
	font-family: "Quicksand", sans-serif;
	left: 0;
	position: relative;
	top: 0;
	text-align: left;
	width: 394px;
}