@import "./../../Style/form";

.input_wrapper {
  position: relative;
  .input_field_wrapper {
    position: relative;
    svg {
      position: absolute;
      bottom: 25px;
      right: 12px;
    }
    &.error {
      svg {
        bottom: 23px;
      }
    }
    input {
      width: 100%;
    }
  }
}
