.text-area-field-container {
	.text-area-field-label {
		font-size: 25px;
		font-weight: 500;
		color: #707070;
	}

	.text-area-field-input {
		border: 1px solid #ECEDEE;
		border-radius: 6px;
		padding: 20px;
		width: 100%;
		height: 100%;
		outline: none;
		resize: none;
	}
}