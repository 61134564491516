.listings-container {
	background-repeat: repeat-x;

	.listings-main {
		background-color: #FFF;

		.listings-title {
			font-size: 18px;
			font-weight: 500;
			color: #06254d;
			padding: 3px 13px;
		}

		.listings-paging {
			font-size: 18px;
			font-weight: 500;
			color: #133C73;
			padding: 0px 15px;
		}
		.nickname {
			font-size: 16px;
			font-weight: 700;
			color: #252627;
			padding: 0px 15px;
		  }

		.property-main-picture-container {
			display: flex;
			flex-direction: column;
			width: 300px;
			justify-content: center;
			align-items: center;
			.img-slider {
			  display: flex;
			  justify-content: center;
			  align-items: center;
			  min-width: 700px;
			}
			.text-title {
				font-size: 8px;
				font-weight: 300;
				color: #07294c;
				padding: 0px 15px;
			  }
	  
			.property-main-picture {
			  width: 200px;
			  height: 100px;
			  position: relative;
			}
	  
			.property-page-prev-next-pic {
			  padding: 8px;
			}
			.garbage-can {
				position: relative;
				top: 3px;
				text-align: center;
				z-index: 10;
			}
		}
	}

	.listings-search-container {
		padding: 2px;
		display: flex;
		align-items: center;
		background-color: rgba(19, 59, 113, 0.8);
		row-gap: 20px;
		//overflow: hidden;
		position: sticky;
		.listings-filter-channel {
			color: white;
			font-size: 12px;
			font-weight: 500;
		}
		.search-main-selection-icons {
			padding-top: 5px;
			display: grid;
			grid-template-columns: 60px 60px 60px 60px;
			justify-items: center;
		}
		.listings-search-input {
			border-radius: 5px;
			font-size: 16px !important;
			padding: 4px;
			height: 60px;
			outline: none;
			border: 0;
			margin-right: 20px;
		}

		.listings-search-separator {
			margin: 0 20px;
			height: 50px;
			width: 1px;
			border-right: 1px solid white;
		}

		.listings-search-add-client-button {
			display: flex;
			align-items: center;
			color: white;
			font-size: 12px;
			font-weight: 500;
			user-select: none;
			cursor: pointer;
		}
	}
}