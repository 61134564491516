.add-to-wishlist-container {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-size: cover;
	background-color: #133b7680;

	.add-to-wishlist-bg {
		display: flex;
		width: 100vw;
		height: 100vh;
		background-color: #133b76d9;
		backdrop-filter: blur(15px);

		.add-to-wishlist-title {
			color: #284866;
			font-size: 25px;
			padding: 20px;
			border-bottom: 1px solid #E7E7E7;
		}

		.add-to-wishlist-footer {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding-top: 20px;
		}
	}
}
