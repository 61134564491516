.advanced-search-filter-wrapper {
	position: relative;

	.advanced-search-filter-container {
		// max-width: 331px;
		height: 80px;
		background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
		background: #FFFFFF 0% 0% no-repeat padding-box;
		box-shadow: 0 3px 6px #0000001F;
		border-radius: 5px;
		cursor: pointer;
		user-select: none;

		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 20px;

		.advanced-search-filter-title {
			color: #2C4861;
			font-size: 25px;
			font-weight: 500;
		}
	}

	.advanced-search-filter-floater {
		position: absolute;
		z-index: 200;
		background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
		background: #FFFFFF 0% 0% no-repeat padding-box;
		box-shadow: 0 3px 6px #0000001F;
		border-radius: 5px;
		font-size: 22px;
		cursor: pointer;
		user-select: none;
		width: 100%;
		min-height: 50px;

		.advanced-search-filter-floater-item {
			padding: 3px 20px;
			cursor: pointer;
			user-select: none;
			background-color: #fefefe;
		}

		.advanced-search-filter-floater-item:hover {

		}

		.advanced-search-filter-floater-item-selected {
			padding: 3px 20px;
			cursor: pointer;
			user-select: none;
			background-color: #f5f5f2;
			font-size: 25px;
		}
	}
}

