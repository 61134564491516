.input_field {
  padding: 14px;
  border-radius: 4px;
  border: 2px solid #ced4da;
  background-color: #fff;
  box-sizing: border-box;
  &:focus {
    background-color: #fff;
    border: 1px solid #80bdff;
    outline: none;
  }

  &.invalid {
    border: 2px solid #d9212c;
    border-radius: 4px;
  }
}

input[type="text"],
input[type="email"],
input[type="password"] {
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
}

.input_wrapper {
  display: flex;
  font-family: "Quicksand", sans-serif;
  flex-direction: column;

  div.label_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    label {
      font-family: "Quicksand", sans-serif;
      font-size: 22px;
      //   font-weight: 600;
      // margin: 30px 0 0 0;
      margin: 10px 0 0 0;
      display: inline-block;
    }

    span {
      font-size: 14px;
      svg {
        display: inline-block;
        margin-right: 4px;
        position: relative;
        top: 2px;
      }

      span {
        color: #165093;
        font-weight: 600;
        display: inline-block;
        margin-left: 6px;
        cursor: pointer;
      }
    }
  }
}
