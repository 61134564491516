.selected-properties-dropdown-container {
	position: relative;
	min-width:200px;
	max-width:600px;

	.selected-properties-dropdown-header {
		background-color: #1B9C5D;
		color: #FFF;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border: 1px solid #70707029;
		border-radius: 5px;
		font-size: 20px;
		font-weight: 500;
		padding: 5px;
		user-select: none;
		cursor: pointer;
	}

	.selected-properties-dropdown-body {
		position: absolute;
		z-index: 1;
		width: 100%;
		background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
		background: #FFFFFF 0% 0% no-repeat padding-box;
		box-shadow: 0 3px 3px #00000029;
		border: 1px solid #A5A1A129;
		border-radius: 0 0 10px 10px;

		.selected-properties-dropdown-body-rows {
			height: 155px;

			.selected-properties-dropdown-body-row {
				padding: 5px;
				background-color: transparent;
				display: flex;
				align-items: center;

				.selected-properties-dropdown-body-row-title {
					font-size: 17px;
					font-weight: 500;
					color: #707070;
					padding-left: 5px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			.selected-properties-dropdown-body-row:hover {
				background-color: #F5F5F2;
				cursor: pointer;
				user-select: none;
			}
		}

		.selected-properties-dropdown-footer {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding: 15px;
		}
	}
}