.page-header-container {
	background-color: rgb(119 198 85);
	overflow: hidden;
	padding: 2px;
	width: 100%;

	.page-header-property-top {
		display: flex;
		justify-content: center;
		flex-direction: column;
	}

	.page-header-top-row2 {
		padding: 5px 2px;
		display: flex;
		align-items: center;
		row-gap: 12px;
	}

	.page-header-top-row2-button {
		margin: 0 5px;
		font-size:28;
		font-family:"Quicksand", sans-serif !important;
	}

	.page-header-top-advanced {
		margin: 10 85px;
		font-size:28;
		font-family:"Quicksand", sans-serif !important;
	}

	.page-header-top-row2-button-advanced {
		cursor: pointer;
	}

	.page-header-top-row3 {
		padding: 0 25px;
		display: flex;
		align-items: center;
		color: white;
		font-size: 20px;
	}
}

