.sidebar-container {
  //background-color: #b9f5d1;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  min-width:400px;
  max-width: 60%;
  background-color: #f5f5f2;
  //overflow-y: hidden;
  z-index: 99999999999;

  .sidebar-logo-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 30px 0;
    width: 100%;
  }

  .sidebar-item {
    color: #2c4861;
    font-size: 30px;
    padding: 20px 0 20px 30px;
     cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
  }

  .sidebar-icon {
    width: 30px;
    height: 30px;
    transition: opacity 0.3s;
  }
}
.sidebar-container-sm {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  // width: 50px;
  height: 35px;
  // height: 200vh;
  background-color: #f5f5f2;
  //overflow-y: hidden;
  z-index: 99999999999;

  // .sidebar-logo-container {
  //   display: flex;
  //   justify-content: center;
  //   align-items: flex-start;
  //   padding: 10px 0;
  //   width: 100%;
  // }

  // .sidebar-item {
  //   color: #2c4861;
  //   font-size: 22px;
  //   padding: 15px 10px 15px 10px;
  //   cursor: pointer;
  //   user-select: none;
  //   display: flex;
  //   align-items: center;
  // }

  // .sidebar-icon {
  //   width: 22px;
  //   height: 22px;
  //   transition: opacity 0.3s;
  // }
}
