
.loading-box {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 9999; 
	background-color: #ffffff00; 
	padding: 20px;
	border-radius: 5px; 
	z-index: 9999999;
  }
  
  .loading-image {
	width: 100px; 
	height: 100px; 
	z-index: 9999999; 
  }
  