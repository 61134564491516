.dropdown-container {
	.dropdown-field-label {
		font-size: 18px;
		font-weight: 500;
		color: #707070;
	}

	.dropdown-field {
		width: 100%;
		height: 50px;
		border-radius: 6px;
		border: 1px solid #ECEDEE;
		outline: none;
	}
}