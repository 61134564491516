.profile-container {
	display: grid;
	background-size: 100% 100%;
	font-size: 25px;
	.profile-main {
		padding: 30px;

		.profile-header {
			font-size: 45px;
			font-weight: 500;
			color: #133C73;
			border-bottom: 1px solid #E7E7E7;
		}

		.profile-body {
			display: grid;
			grid-template-columns: 900px 1fr;
			font-size: 25px;
			.profile-body-right {
				margin: 20px;
				width: 400px;

				.profile-body-right-top {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}
				.image-input {
					position: relative;
					display: inline-block;
					border-radius: 0.475rem;
					background-repeat: no-repeat;
					background-size: cover;
					width:100%;
					height:100%;
				  }
				  .image-input:not(.image-input-empty) {
					background-image: none !important;
				  }
				  .image-input .image-input-wrapper {
					width: 250px;
					height: 250px;
					border-radius: 0.475rem;
					background-repeat: no-repeat;
					background-size: cover;
				  }
				  .image-input [data-mk-image-input-action] {
					cursor: pointer;
					position: absolute;
					transform: translate(-50%, -50%);
				  }
				  .image-input [data-mk-image-input-action="change"] {
					left: 100%;
					top: 0;
				  }
				  .image-input [data-mk-image-input-action="change"] input {
					width: 0 !important;
					height: 0 !important;
					overflow: hidden;
					opacity: 0;
				  }
				  .image-input [data-mk-image-input-action="cancel"],
				  .image-input [data-mk-image-input-action="remove"] {
					position: absolute;
					left: 100%;
					top: 100%;
				  }
				  .image-input [data-mk-image-input-action="cancel"] {
					display: none;
				  }
				  .image-input.image-input-changed [data-mk-image-input-action="cancel"] {
					display: flex;
				  }
				  .image-input.image-input-changed [data-mk-image-input-action="remove"] {
					display: none;
				  }
				  .image-input.image-input-empty [data-mk-image-input-action="remove"],
				  .image-input.image-input-empty [data-mk-image-input-action="cancel"] {
					display: none;
				  }
				  .image-input.image-input-circle {
					border-radius: 50%;
				  }
				  .image-input.image-input-circle .image-input-wrapper {
					border-radius: 50%;
				  }
				  .image-input.image-input-circle [data-mk-image-input-action="change"] {
					left: 100%;
					top: 0;
					transform: translate(-100%, 0%);
				  }
				  .image-input.image-input-circle [data-mk-image-input-action="cancel"],
				  .image-input.image-input-circle [data-mk-image-input-action="remove"] {
					left: 100%;
					top: 100%;
					transform: translate(-100%, -100%);
				  }
				  .image-input.image-input-outline .image-input-wrapper {
					border: 3px solid #ffffff;
					box-shadow: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05);
				  }
				  
				  .w-125px {
					width: 250px !important;
				  }
				  
				  .h-125px {
					height: 250px !important;
				  }
				  
				  .w-25px {
					width: 25px !important;
				  }
				  .h-25px {
					height: 25px !important;
				  }
				  .shadow {
					box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075) !important;
				  }
				  
				  .label-image {
					position: relative;
					display: inline-block;
					border-radius: 0.475rem;
					background-repeat: no-repeat;
					background-size: cover;
				  }
				  
				  .label-extra {
					cursor: pointer;
					position: absolute;
					-webkit-transform: translate(-50%, -50%);
					transform: translate(-50%, -50%);
					left: 50%;
					top: 0%;
				  }
				  
				  .btn-icon {
					display: inline-flex;
					align-items: center;
					justify-content: center;
					padding: 0;
					/* height: calc(1.5em + 1.5rem + 2px); */
					width: calc(1.5em + 1.5rem + 2px);
				  }
				  
				  .form-text {
					font-size: 8px;
					font-weight: 500;
					text-align: center;
				  }
				  
				.profile-body-right-bottom {
					background-color: #E4E4E440;
					border: 1px solid #E5E5E5;
					border-radius: 10px;
					padding: 20px;
					margin-top: 20px;
					height: 250px;
					width: 600px;

				}
			}
		}
	}
}