.share-selection-container {
	display: grid;
	grid-template-rows: 100px 1fr 100px;
	height: 700px;

	.share-selection-header {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 35px;
		font-weight: 500;
		color: #284866;
		user-select: none;
		cursor: default;
	}

	.share-selection-main {
		border: 1px solid #00000029;
		border-width: 1px 0;
		padding: 20px 30px;

		.share-selection-add-message-link {
			margin-top: 40px;
			font-size: 17px;
			font-weight: 500;
			color: #036AE1;
			text-decoration: underline;
			user-select: none;
			cursor: pointer;

		}
	}

	.share-selection-footer {
		background-color: #FDFDFD;
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 20px;
	}
}