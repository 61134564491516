.input-field-container {
	.input-field-label {
		font-size: 25px;
		font-weight: 500;
		color: #707070;
	}

	.input-field-input {
		border: 1px solid #ECEDEE;
		border-radius: 6px;
		font-size: 30px;
		padding: 10px;
		outline: none;
		width: 90%;
	}
}