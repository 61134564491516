.ps-2 {
	font-size: 15px;
}

.agencies-container {
	background-repeat: repeat-x;

	.agencies-floating-edit-menu {
		left: 0px;
		position: absolute;
		z-index: 99;
		background-color: white;
		box-shadow: 0 3px 6px #00000029;
		color: #707070;
		width: 200px;
		border-radius: 3px;
		border: 1px solid #DEDEDE;
		font-size: 10px;
		line-height: 26px;

		.agencies-floating-edit-menu-row {
			display: flex;
			align-items: center;
			padding: 5px 10px;
			cursor: pointer;
			width:200px;
		}

		.agencies-floating-edit-menu-row:hover {
			background-color: #F5F5F2;
		}
	}

	.agencies-floating-edit-menu-floater {
		position: fixed;
		height: 100vh;
		width: 100vw;
		background-color: transparent;
		top: 0;
		left: 0;
		z-index: 9;
	}

	.agencies-main {
		background-color: #FFF;

		.agencies-edit-icon {
			position: relative;
			cursor: pointer;
			display: flex;
			width: 100%;
			height: 100%;
			align-items: center;
			background-color: #F9F9F8;
			justify-content: center;
		}

		.agencies-title {
			font-size: 20px;
			font-weight: 500;
			color: #133C73;
			padding: 0px 10px;
		}
		.agencies-main-subtitle {
			font-size: 20px;
			font-weight: 500;
			color: #95999e;
			padding: 5px;
			text-align: left;
		}
	}

	.agencies-search-container {
		padding: 15px;
		display: flex;
		align-items: center;
		background-color: rgba(19, 59, 113, 0.8);
		grid-template-rows: 100px 100px 100px 1fr;
		overflow: hidden;

		.agencies-search-input {
			border-radius: 5px;
			font-size: 15px !important;
			padding: 10px;
			height: 60px;
			outline: none;
			border: 0;
			margin-right: 20px;
		}

		.agencies-search-separator {
			margin: 0 20px;
			height: 50px;
			width: 1px;
			border-right: 1px solid white;
		}

		.agencies-search-add-client-button {
			display: flex;
			align-items: center;
			color: white;
			font-size: 15px;
			font-weight: 500;
			user-select: none;
			cursor: pointer;
		}
	}
}