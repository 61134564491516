.guests-picker-wrapper {
	.guests-picker-container {
		
		height: 60px;
		background-color: white;
		border-radius: 4px;
		display: flex;
		align-items: center;
		padding: 0 10px;
		font-size: 15px;
		font-weight: 500;
		cursor: default;
		user-select: none;
	}

	.guests-picker-text {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.guests-picker-floater {
		position: absolute;
		z-index: 2;
		background-color: rgb(231, 231, 231);

		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		padding: 20px;
		display: grid;
		grid-template-rows: 1fr 1fr 1fr 1fr;
		.guests-titles {
			font-size: 12px;
		}
	}
}

.page-transparent-blocker {
	position: absolute;
	z-index: 1;
	background-color: transparent;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}