.favorites-container {

	.favorites-results {
		padding: 20px;
		position: relative;

		.favorites-results-buttons {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.favorites-top-panel {
			display: flex;
			justify-content: space-between;
		}

		.favorites-main-title {
			font-size: 40px;
			color: #284866;
		}

		.favorites-main-subtitle {
			font-size: 18px;
			color: #284866;
		}

		.favorites-main-selection-title {
			font-size: 20px;
			font-weight: 500;
			color: #284866;
			padding-top: 40px;
		}

		.favorites-main-selection-icons {
			padding-top: 10px;
			display: grid;
			grid-template-columns: 80px 80px 80px 80px;
			align-items: center;
			justify-items: center;
		}

		.favorites-boxes {
			grid-row-gap: 30px;
			width: 100%;
			// display: flex;
			// justify-content: space-between;
		}
	}

	.favorites-no-results-container {
		background-size: cover;
		backdrop-filter: blur(15px);
		background-color: hsl(216deg 72% 27% / 50%);

		.favorites-no-results-main {
			padding-top: 10px;
			display: flex;
			flex-direction: column;
			align-items: center;
			color: white;
		}
	}

}

