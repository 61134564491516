.interactive-map {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.interactive-map-container {
    width: 100%;
    height: 100%;
}

.continent-text {
    position: absolute;
    bottom: 0px;
    right: 20px;
    z-index: 1;
    padding: 5px;
    background-color: none;
    border-radius: 5px;
    border: none;
    color: #999999;
    font-family: 'Times New Roman', Times, serif;
     font-size: 50px;
    cursor: pointer;
    text-transform: uppercase;
}

#zoom-out-button {
    position: absolute;
    
    
    z-index: 1;
    background-color: #fff;
    border: "1px solid f2f2f2";
    box-shadow: 0 0 0 0.8px gray;
}

#zoom-out-button:hover {
    background-color: #f2f2f2;
    color: #000;
}

.earth-icon-button {
    background-color: #fff;
    color: #000;
    border-radius: 3px;
    padding: 3px;
    font-size: 14px;
    cursor: pointer;
}

.earth-icon-button i {
    display: block;
}

#country-top-destination {
    
    
    
    right: 0px; 
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.country-info {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.top-destination-container {
    display: flex;
    flex-direction: row;
}

.top-destination {
    border: 1px solid gray;
    border-radius: 5px;
    padding: 1%;
    margin: 5px;
}

.custom-continent-marker {
    position: absolute;
    border: 3px solid green;
    height: 10px;
    width: 10px;
    color: #FFF;
    background: #fff;
    border-radius: 50% 50% 50% 0;
    

    
      background-size: cover;
      width: 50px;
      height: 50px;
      cursor: pointer; 
}

.custom-country-marker {
    position: absolute;
    border: 3px solid #FFF;
    height: 10px;
    width: 10px;
    color: #FFF;
    background: green;
    border-radius: 50% 50% 50% 0;
}

.custom-single-country-marker {
    position: absolute;
    border: 3px solid green;
    height: 10px;
    width: 10px;
    color: #FFF;
    background: green;
    border-radius: 50% 50% 50% 0;
}

.maplibregl-map{
    position: absolute !important;
}