.property-page-wrapper {

	.edit-property-page-container {
		background-color: rgb(227, 252, 233);

		.mediaViewInfo {
			--web-view-name: 27// Web 1920X1080_Property_Request a Hold – 2;
			--web-view-id: n_7_Web_1920X1080_Property_Request_a_Hold__2;
			--web-scale-on-resize: true;
			--web-enable-deep-linking: true;
		}
		:root {
			--web-view-ids: n_7_Web_1920X1080_Property_Request_a_Hold__2;
		}
		* {
			margin: 0;
			padding: 0;
			box-sizing: border-box;
			border: none;
		}
		#n_7_Web_1920X1080_Property_Request_a_Hold__2 {
			position: absolute;
			width: 1920px;
			height: 2808px;
			background-color: rgba(255,255,255,1);
			overflow: hidden;
			--web-view-name: 27// Web 1920X1080_Property_Request a Hold – 2;
			--web-view-id: n_7_Web_1920X1080_Property_Request_a_Hold__2;
			--web-scale-on-resize: true;
			--web-enable-deep-linking: true;
		}
		#bg_inn {
			fill: rgba(253,253,253,1);
		}
		.bg_inn {
			position: absolute;
			overflow: visible;
			width: 1920px;
			height: 2792px;
			left: 0px;
			top: 0px;
		}
		#Scroll_Group_2 {
			mix-blend-mode: normal;
			position: absolute;
			width: 1686.718px;
			height: 254.214px;
			left: 232.281px;
			top: 75.085px;
			display: none;
			overflow: hidden;
		}
		#screen_shot_2020_07__jk7r5 {
			position: absolute;
			width: 1686.717px;
			height: 1097.214px;
			left: 0px;
			top: -284px;
			overflow: visible;
		}
		#Bg_header {
			opacity: 0.85;
			fill: rgba(19,59,113,1);
		}
		.Bg_header {
			display: none;
			overflow: visible;
			position: absolute;
			width: 1656.666px;
			height: 254.299px;
			left: 259.332px;
			top: 75px;
			transform: matrix(1,0,0,1,0,0);
		}
		#pdf_1 {
			position: absolute;
			width: 20.5px;
			height: 24.967px;
			left: 196.391px;
			top: 196.338px;
			overflow: visible;
		}
		#Group_602 {
			position: absolute;
			width: 20.5px;
			height: 24.967px;
			left: 0px;
			top: 0px;
			overflow: visible;
		}
		#Group_601 {
			position: absolute;
			width: 20.5px;
			height: 24.967px;
			left: 0px;
			top: 0px;
			overflow: visible;
		}
		#Path_3022 {
			fill: rgba(255,255,255,1);
			stroke: rgba(255,255,255,1);
			stroke-width: 0.30000001192092896px;
			stroke-linejoin: miter;
			stroke-linecap: butt;
			stroke-miterlimit: 4;
			shape-rendering: auto;
		}
		.Path_3022 {
			overflow: visible;
			position: absolute;
			width: 20.801px;
			height: 25.267px;
			left: 0px;
			top: 0px;
			transform: matrix(1,0,0,1,0,0);
		}
		#Path_3023 {
			fill: rgba(255,255,255,1);
			stroke: rgba(255,255,255,1);
			stroke-width: 0.30000001192092896px;
			stroke-linejoin: miter;
			stroke-linecap: butt;
			stroke-miterlimit: 4;
			shape-rendering: auto;
		}
		.Path_3023 {
			overflow: visible;
			position: absolute;
			width: 13.967px;
			height: 13.966px;
			left: 3.418px;
			top: 7.534px;
			transform: matrix(1,0,0,1,0,0);
		}
		#Down_Arrow_3_ {
			transform: matrix(1,0,0,1,1167.9999,327.3104) rotate(180deg);
			transform-origin: center;
			position: absolute;
			width: 24.664px;
			height: 45.217px;
			left: 0px;
			top: 0px;
			overflow: visible;
		}
		#Down_Arrow_3__r {
			fill: rgba(22,80,147,1);
		}
		.Down_Arrow_3__r {
			overflow: visible;
			position: absolute;
			width: 45.217px;
			height: 24.664px;
			transform: translate(24.664px, 45.217px) matrix(1,0,0,1,-10.2764,10.2764) rotate(-90deg);
			transform-origin: center;
			left: 0px;
			top: 0px;
		}
		#Image_7 {
			position: absolute;
			width: 701.561px;
			height: 470.458px;
			left: 449px;
			top: 128.224px;
			overflow: visible;
		}
		#Down_Arrow_3__t {
			opacity: 0.5;
			position: absolute;
			width: 24.664px;
			height: 45.217px;
			left: 406.896px;
			top: 327.311px;
			overflow: visible;
		}
		#Down_Arrow_3__u {
			fill: rgba(22,80,147,1);
		}
		.Down_Arrow_3__u {
			overflow: visible;
			position: absolute;
			width: 45.217px;
			height: 24.664px;
			transform: translate(0px, 0px) matrix(1,0,0,1,-10.2764,10.2764) rotate(-90deg);
			transform-origin: center;
			left: 0px;
			top: 0px;
		}
		#Group_283 {
			position: absolute;
			width: 48.613px;
			height: 44.287px;
			left: 294.266px;
			top: 771.206px;
			overflow: visible;
		}
		#g916 {
			position: absolute;
			width: 48.613px;
			height: 44.287px;
			left: 0px;
			top: 0px;
			overflow: visible;
		}
		#n_6 {
			left: 55.926px;
			top: 849.489px;
			position: absolute;
			overflow: visible;
			width: 22px;
			white-space: nowrap;
			text-align: left;
			font-family: Quicksand;
			font-style: normal;
			font-weight: normal;
			font-size: 23px;
			color: rgba(170,170,170,1);
		}
		#n_ {
			left: 183.762px;
			top: 849.489px;
			position: absolute;
			overflow: visible;
			width: 15px;
			white-space: nowrap;
			text-align: left;
			font-family: Quicksand;
			font-style: normal;
			font-weight: normal;
			font-size: 25px;
			color: rgba(170,170,170,1);
		}
		#n_0 {
			left: 303.586px;
			top: 847.477px;
			position: absolute;
			overflow: visible;
			width: 24px;
			white-space: nowrap;
			text-align: left;
			font-family: Quicksand;
			font-style: normal;
			font-weight: normal;
			font-size: 24px;
			color: rgba(170,170,170,1);
		}
		#g69 {
			position: absolute;
			width: 57.815px;
			height: 55.075px;
			left: 164.957px;
			top: 765.745px;
			overflow: visible;
		}
		#g71 {
			position: absolute;
			width: 57.815px;
			height: 55.075px;
			left: 0px;
			top: 0px;
			overflow: visible;
		}
		#g73 {
			position: absolute;
			width: 57.814px;
			height: 55.075px;
			left: 0px;
			top: 0px;
			overflow: visible;
		}
		#Layer_3 {
			position: absolute;
			width: 52.61px;
			height: 45.941px;
			left: 41.635px;
			top: 765.868px;
			overflow: visible;
		}
		#Group_282 {
			position: absolute;
			width: 52.61px;
			height: 45.941px;
			left: 0px;
			top: 0px;
			overflow: visible;
		}
		#ARC_423_ {
			position: absolute;
			width: 17.71px;
			height: 5.033px;
			left: 34.389px;
			top: 32.415px;
			overflow: visible;
		}
		#Group_264 {
			position: absolute;
			width: 17.71px;
			height: 5.033px;
			left: 0px;
			top: 0px;
			overflow: visible;
		}
		#Path_2919 {
			fill: rgba(40,72,102,1);
		}
		.Path_2919 {
			overflow: visible;
			position: absolute;
			width: 17.711px;
			height: 5.033px;
			left: 0px;
			top: 0px;
			transform: matrix(1,0,0,1,0,0);
		}
		#ARC_422_ {
			position: absolute;
			width: 10.528px;
			height: 6.98px;
			left: 26.67px;
			top: 17.979px;
			overflow: visible;
		}
		#Group_265 {
			position: absolute;
			width: 10.528px;
			height: 6.98px;
			left: 0px;
			top: 0px;
			overflow: visible;
		}
		#Path_2920 {
			fill: rgba(40,72,102,1);
		}
		.Path_2920 {
			overflow: visible;
			position: absolute;
			width: 10.529px;
			height: 6.979px;
			left: 0px;
			top: 0px;
			transform: matrix(1,0,0,1,0,0);
		}
		#CIRCLE_38_ {
			position: absolute;
			width: 18.193px;
			height: 18.192px;
			left: 29.795px;
			top: 2.569px;
			overflow: visible;
		}
		#Group_266 {
			position: absolute;
			width: 18.193px;
			height: 18.192px;
			left: 0px;
			top: 0px;
			overflow: visible;
		}
		#Path_2921 {
			fill: rgba(40,72,102,1);
		}
		.Path_2921 {
			overflow: visible;
			position: absolute;
			width: 18.193px;
			height: 18.192px;
			left: -0.002px;
			top: 0px;
			transform: matrix(1,0,0,1,0,0);
		}
		#ARC_421_ {
			position: absolute;
			width: 12.029px;
			height: 12.028px;
			left: 40.582px;
			top: 17.98px;
			overflow: visible;
		}
		#Group_267 {
			position: absolute;
			width: 12.029px;
			height: 12.028px;
			left: 0px;
			top: 0px;
			overflow: visible;
		}
		#Path_2922 {
			fill: rgba(40,72,102,1);
		}
		.Path_2922 {
			overflow: visible;
			position: absolute;
			width: 12.029px;
			height: 12.028px;
			left: 0px;
			top: 0px;
			transform: matrix(1,0,0,1,0,0);
		}
		#LINE_818_ {
			position: absolute;
			width: 8.947px;
			height: 2.782px;
			left: 34.418px;
			top: 17.979px;
			overflow: visible;
		}
		#Group_268 {
			position: absolute;
			width: 8.947px;
			height: 2.782px;
			left: 0px;
			top: 0px;
			overflow: visible;
		}
		#Path_2923 {
			fill: rgba(40,72,102,1);
		}
		.Path_2923 {
			overflow: visible;
			position: absolute;
			width: 8.945px;
			height: 2.782px;
			left: 0px;
			top: 0px;
			transform: matrix(1,0,0,1,0,0);
		}
		#LINE_817_ {
			position: absolute;
			width: 2.782px;
			height: 7.405px;
			left: 49.828px;
			top: 27.226px;
			overflow: visible;
		}
		#Group_269 {
			position: absolute;
			width: 2.782px;
			height: 7.405px;
			left: 0px;
			top: 0px;
			overflow: visible;
		}
		#Path_2924 {
			fill: rgba(40,72,102,1);
		}
		.Path_2924 {
			overflow: visible;
			position: absolute;
			width: 2.783px;
			height: 7.405px;
			left: 0px;
			top: 0px;
			transform: matrix(1,0,0,1,0,0);
		}
		#ARC_420_ {
			position: absolute;
			width: 15.11px;
			height: 15.11px;
			left: 0.002px;
			top: 20.548px;
			overflow: visible;
		}
		#Group_270 {
			position: absolute;
			width: 15.11px;
			height: 15.11px;
			left: 0px;
			top: 0px;
			overflow: visible;
		}
		#Path_2925 {
			fill: rgba(40,72,102,1);
		}
		.Path_2925 {
			overflow: visible;
			position: absolute;
			width: 15.109px;
			height: 15.11px;
			left: 0px;
			top: 0px;
			transform: matrix(1,0,0,1,0,0);
		}
		#LINE_816_ {
			position: absolute;
			width: 11.002px;
			height: 2.782px;
			left: 12.328px;
			top: 20.548px;
			overflow: visible;
		}
		#Group_271 {
			position: absolute;
			width: 11.002px;
			height: 2.782px;
			left: 0px;
			top: 0px;
			overflow: visible;
		}
		#Path_2926 {
			fill: rgba(40,72,102,1);
		}
		.Path_2926 {
			overflow: visible;
			position: absolute;
			width: 11px;
			height: 2.783px;
			left: 0.002px;
			top: 0px;
			transform: matrix(1,0,0,1,0,0);
		}
		#LINE_815_ {
			position: absolute;
			width: 2.782px;
			height: 8.947px;
			left: 0.002px;
			top: 32.876px;
			overflow: visible;
		}
		#Group_273 {
			position: absolute;
			width: 2.782px;
			height: 8.947px;
			left: 0px;
			top: 0px;
			overflow: visible;
		}
		#Path_2928 {
			fill: rgba(40,72,102,1);
		}
		.Path_2928 {
			overflow: visible;
			position: absolute;
			width: 2.781px;
			height: 8.947px;
			left: 0px;
			top: 0px;
			transform: matrix(1,0,0,1,0,0);
		}
		#CIRCLE_37_ {
			position: absolute;
			width: 23.331px;
			height: 23.331px;
			left: 6.164px;
			top: 0px;
			overflow: visible;
		}
		#Group_274 {
			position: absolute;
			width: 23.331px;
			height: 23.331px;
			left: 0px;
			top: 0px;
			overflow: visible;
		}
		#Path_2929 {
			fill: rgba(40,72,102,1);
		}
		.Path_2929 {
			overflow: visible;
			position: absolute;
			width: 23.33px;
			height: 23.331px;
			left: 0px;
			top: 0px;
			transform: matrix(1,0,0,1,0,0);
		}
		#ARC_418_ {
			position: absolute;
			width: 15.11px;
			height: 15.11px;
			left: 20.549px;
			top: 20.548px;
			overflow: visible;
		}
		#Group_275 {
			position: absolute;
			width: 15.11px;
			height: 15.11px;
			left: 0px;
			top: 0px;
			overflow: visible;
		}
		#Path_2930 {
			fill: rgba(40,72,102,1);
		}
		.Path_2930 {
			overflow: visible;
			position: absolute;
			width: 15.111px;
			height: 15.11px;
			left: -0.002px;
			top: 0px;
			transform: matrix(1,0,0,1,0,0);
		}
		#LINE_814_ {
			position: absolute;
			width: 2.782px;
			height: 8.947px;
			left: 32.875px;
			top: 32.876px;
			overflow: visible;
		}
		#Group_276 {
			position: absolute;
			width: 2.782px;
			height: 8.947px;
			left: 0px;
			top: 0px;
			overflow: visible;
		}
		#Path_2931 {
			fill: rgba(40,72,102,1);
		}
		.Path_2931 {
			overflow: visible;
			position: absolute;
			width: 2.783px;
			height: 8.947px;
			left: 0px;
			top: 0px;
			transform: matrix(1,0,0,1,0,0);
		}
		#ARC_417_ {
			position: absolute;
			width: 35.661px;
			height: 6.901px;
			left: 0px;
			top: 39.04px;
			overflow: visible;
		}
		#Group_277 {
			position: absolute;
			width: 35.661px;
			height: 6.901px;
			left: 0px;
			top: 0px;
			overflow: visible;
		}
		#Path_2932 {
			fill: rgba(40,72,102,1);
		}
		.Path_2932 {
			overflow: visible;
			position: absolute;
			width: 35.662px;
			height: 6.901px;
			left: 0px;
			top: 0px;
			transform: matrix(1,0,0,1,0,0);
		}
		#Bathroom {
			left: 281.096px;
			top: 825.417px;
			position: absolute;
			overflow: visible;
			width: 77px;
			white-space: nowrap;
			text-align: left;
			font-family: Quicksand;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			color: rgba(170,170,170,1);
		}
		#Bedrooms {
			left: 151.787px;
			top: 825.417px;
			position: absolute;
			overflow: visible;
			width: 78px;
			white-space: nowrap;
			text-align: left;
			font-family: Quicksand;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			color: rgba(170,170,170,1);
		}
		#Guests {
			left: 41px;
			top: 825.926px;
			position: absolute;
			overflow: visible;
			width: 52px;
			white-space: nowrap;
			text-align: left;
			font-family: Quicksand;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			color: rgba(170,170,170,1);
		}
		#g815 {
			position: absolute;
			width: 59.807px;
			height: 59.807px;
			left: 454.182px;
			top: 761.012px;
			overflow: visible;
		}
		#g817 {
			position: absolute;
			width: 59.807px;
			height: 59.807px;
			left: 0px;
			top: 0px;
			overflow: visible;
		}
		#Event_Places {
			left: 437.977px;
			top: 825.417px;
			position: absolute;
			overflow: visible;
			width: 96px;
			white-space: nowrap;
			text-align: left;
			font-family: Quicksand;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			color: rgba(170,170,170,1);
		}
		#Guesty_TAG {
			left: 437.977px;
			top: 850.015px;
			position: absolute;
			overflow: visible;
			width: 88px;
			white-space: nowrap;
			text-align: left;
			font-family: Quicksand;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			color: rgba(170,170,170,1);
		}
		#X_TAG {
			left: 584.664px;
			top: 850.758px;
			position: absolute;
			overflow: visible;
			width: 46px;
			white-space: nowrap;
			text-align: left;
			font-family: Quicksand;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			color: rgba(170,170,170,1);
		}
		#Family_friendly {
			left: 551.164px;
			top: 825.417px;
			position: absolute;
			overflow: visible;
			width: 113px;
			white-space: nowrap;
			text-align: left;
			font-family: Quicksand;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			color: rgba(170,170,170,1);
		}
		#Path_3335 {
			fill: rgba(0,0,0,0);
			stroke: rgba(231,231,231,1);
			stroke-width: 1px;
			stroke-linejoin: miter;
			stroke-linecap: butt;
			stroke-miterlimit: 4;
			shape-rendering: auto;
		}
		.Path_3335 {
			overflow: visible;
			position: absolute;
			width: 1589.004px;
			height: 4.664px;
			left: 41px;
			top: 902.282px;
			transform: matrix(1,0,0,1,0,0);
		}
		#Group_1288 {
			position: absolute;
			width: 817px;
			height: 77px;
			left: 40px;
			top: 643px;
			overflow: visible;
		}
		#France_France_Cannes_Cedex {
			left: 3px;
			top: 52px;
			position: absolute;
			overflow: visible;
			width: 702px;
			height: 25px;
			text-align: left;
			font-family: Quicksand;
			font-style: normal;
			font-weight: normal;
			font-size: 20px;
			color: rgba(40,72,102,1);
		}
		#Renovated_19th_Century_Crown_J {
			left: 0px;
			top: 0px;
			position: absolute;
			overflow: hidden;
			width: 818px;
			height: 37px;
			text-align: left;
			font-family: Quicksand;
			font-style: normal;
			font-weight: normal;
			font-size: 40px;
			color: rgba(40,72,102,1);
		}
		#Group_762 {
			position: absolute;
			width: 173.833px;
			height: 14.644px;
			left: 713px;
			top: 609.467px;
			overflow: visible;
		}
		#Group_650 {
			position: absolute;
			width: 173.833px;
			height: 14.644px;
			left: 0px;
			top: 0px;
			overflow: visible;
		}
		#Ellipse_75 {
			fill: rgba(209,209,209,1);
		}
		.Ellipse_75 {
			position: absolute;
			overflow: visible;
			width: 7.559px;
			height: 8.03px;
			left: 0px;
			top: 3.307px;
		}
		#Ellipse_76 {
			fill: rgba(209,209,209,1);
		}
		.Ellipse_76 {
			position: absolute;
			overflow: visible;
			width: 7.559px;
			height: 8.03px;
			left: 18.422px;
			top: 3.307px;
		}
		#Ellipse_77 {
			fill: rgba(68,200,245,1);
		}
		.Ellipse_77 {
			position: absolute;
			overflow: visible;
			width: 8.031px;
			height: 8.03px;
			left: 36.846px;
			top: 3.307px;
		}
		#Ellipse_78 {
			fill: rgba(209,209,209,1);
		}
		.Ellipse_78 {
			position: absolute;
			overflow: visible;
			width: 7.559px;
			height: 8.03px;
			left: 55.268px;
			top: 3.307px;
		}
		#Ellipse_79 {
			fill: rgba(209,209,209,1);
		}
		.Ellipse_79 {
			position: absolute;
			overflow: visible;
			width: 7.559px;
			height: 8.03px;
			left: 73.689px;
			top: 3.307px;
		}
		#Ellipse_80 {
			fill: rgba(209,209,209,1);
		}
		.Ellipse_80 {
			position: absolute;
			overflow: visible;
			width: 7.559px;
			height: 8.03px;
			left: 92.586px;
			top: 3.307px;
		}
		#Ellipse_81 {
			fill: rgba(209,209,209,1);
		}
		.Ellipse_81 {
			position: absolute;
			overflow: visible;
			width: 7.559px;
			height: 8.03px;
			left: 110.535px;
			top: 3.307px;
		}
		#Ellipse_82 {
			fill: rgba(209,209,209,1);
		}
		.Ellipse_82 {
			position: absolute;
			overflow: visible;
			width: 8.031px;
			height: 8.03px;
			left: 128.957px;
			top: 3.307px;
		}
		#Ellipse_83 {
			fill: rgba(209,209,209,1);
		}
		.Ellipse_83 {
			position: absolute;
			overflow: visible;
			width: 7.559px;
			height: 8.03px;
			left: 147.381px;
			top: 3.307px;
		}
		#Ellipse_84 {
			fill: rgba(209,209,209,1);
		}
		.Ellipse_84 {
			position: absolute;
			overflow: visible;
			width: 8.031px;
			height: 8.03px;
			left: 165.803px;
			top: 3.307px;
		}
		#Ellipse_85 {
			fill: transparent;
		}
		.Ellipse_85 {
			position: absolute;
			overflow: visible;
			width: 14.645px;
			height: 14.644px;
			left: 33.539px;
			top: 0px;
		}
		#Group_1336 {
			position: absolute;
			width: 324.336px;
			height: 99.008px;
			left: 928.324px;
			top: 736.917px;
			overflow: visible;
		}
		#Group_645 {
			position: absolute;
			width: 323.832px;
			height: 99.008px;
			left: 0px;
			top: 0px;
			overflow: visible;
		}
		#Group_284 {
			position: absolute;
			width: 323.832px;
			height: 99.008px;
			left: 0px;
			top: 0px;
			overflow: visible;
		}
		#n_3028_ {
			left: 24.402px;
			top: 0px;
			position: absolute;
			overflow: visible;
			width: 169px;
			white-space: nowrap;
			text-align: left;
			font-family: Quicksand;
			font-style: normal;
			font-weight: bold;
			font-size: 53px;
			color: rgba(40,72,102,1);
		}
		#Text {
			left: 0px;
			top: 6.655px;
			position: absolute;
			overflow: visible;
			width: 17px;
			white-space: nowrap;
			text-align: left;
			font-family: Quicksand;
			font-style: normal;
			font-weight: bold;
			font-size: 27px;
			color: rgba(40,72,102,1);
		}
		#n_302_ {
			left: 18.857px;
			top: 61.008px;
			position: absolute;
			overflow: visible;
			width: 77px;
			white-space: nowrap;
			text-align: left;
			font-family: Quicksand;
			font-style: normal;
			font-weight: normal;
			font-size: 30px;
			color: rgba(40,72,102,1);
		}
		#Text_c {
			left: 0px;
			top: 62.118px;
			position: absolute;
			overflow: visible;
			width: 13px;
			white-space: nowrap;
			text-align: left;
			font-family: Quicksand;
			font-style: normal;
			font-weight: normal;
			font-size: 21px;
			color: rgba(40,72,102,1);
		}
		#Agency_Commission {
			left: 99.832px;
			top: 64.336px;
			position: absolute;
			overflow: visible;
			width: 225px;
			white-space: nowrap;
			text-align: center;
			font-family: Quicksand;
			font-style: normal;
			font-weight: normal;
			font-size: 24px;
			color: rgba(40,72,102,1);
		}
		#Per_night {
			left: 196.336px;
			top: 17.748px;
			position: absolute;
			overflow: visible;
			width: 129px;
			white-space: nowrap;
			text-align: left;
			font-family: Quicksand;
			font-style: normal;
			font-weight: normal;
			font-size: 30px;
			color: rgba(40,72,102,1);
		}
		#channel {
			left: 142px;
			top: 139px;
			position: absolute;
			overflow: visible;
			width: 113px;
			white-space: nowrap;
			text-align: center;
			font-family: Quicksand;
			font-style: normal;
			font-weight: bold;
			font-size: 30px;
			color: rgba(166,166,166,1);
		}
		#xdatapmName {
			left: 28px;
			top: 63px;
			position: absolute;
			overflow: visible;
			width: 298px;
			white-space: nowrap;
			text-align: left;
			font-family: Quicksand;
			font-style: normal;
			font-weight: bold;
			font-size: 40px;
			color: rgba(22,80,147,1);
		}
		#n_423425 {
			left: 28px;
			top: 111px;
			position: absolute;
			overflow: visible;
			width: 104px;
			white-space: nowrap;
			line-height: 27px;
			margin-top: -1px;
			text-align: left;
			font-family: Quicksand;
			font-style: normal;
			font-weight: bold;
			font-size: 25px;
			color: rgba(22,80,147,1);
		}
		#n_id {
			left: 400px;
			top: 63px;
			position: absolute;
			overflow: visible;
			width: 64px;
			white-space: nowrap;
			text-align: left;
			font-family: Quicksand;
			font-style: normal;
			font-weight: bold;
			font-size: 25px;
			color: rgba(22,80,147,1);
		}
		#updatedAt {
			left: 1090px;
			top: 63px;
			position: absolute;
			overflow: visible;
			width: 205px;
			white-space: nowrap;
			text-align: left;
			font-family: Quicksand;
			font-style: normal;
			font-weight: bold;
			font-size: 40px;
			color: rgba(22,80,147,1);
		}
		#xdataapprvedAt {
			left: 751px;
			top: 63px;
			position: absolute;
			overflow: visible;
			width: 328px;
			white-space: nowrap;
			text-align: left;
			font-family: Quicksand;
			font-style: normal;
			font-weight: bold;
			font-size: 40px;
			color: rgba(22,80,147,1);
		}
		#property_ID {
			left: 452px;
			top: 27px;
			position: absolute;
			overflow: visible;
			width: 113px;
			white-space: nowrap;
			line-height: 27px;
			margin-top: -3.5px;
			text-align: center;
			font-family: Quicksand;
			font-style: normal;
			font-weight: bold;
			font-size: 20px;
			color: rgba(166,166,166,1);
		}
		#Last_calendar_sync {
			left: 1089px;
			top: 27px;
			position: absolute;
			overflow: visible;
			width: 183px;
			white-space: nowrap;
			line-height: 27px;
			margin-top: -3.5px;
			text-align: center;
			font-family: Quicksand;
			font-style: normal;
			font-weight: bold;
			font-size: 20px;
			color: rgba(166,166,166,1);
		}
		#Approved_at {
			left: 754px;
			top: 27px;
			position: absolute;
			overflow: visible;
			width: 121px;
			white-space: nowrap;
			line-height: 27px;
			margin-top: -3.5px;
			text-align: center;
			font-family: Quicksand;
			font-style: normal;
			font-weight: bold;
			font-size: 20px;
			color: rgba(166,166,166,1);
		}
		#PM {
			left: 32px;
			top: 28px;
			position: absolute;
			overflow: visible;
			width: 29px;
			white-space: nowrap;
			line-height: 27px;
			margin-top: -3.5px;
			text-align: left;
			font-family: Quicksand;
			font-style: normal;
			font-weight: bold;
			font-size: 20px;
			color: rgba(166,166,166,1);
		}
		#country {
			left: 1235px;
			top: 275px;
			position: absolute;
			overflow: visible;
			width: 113px;
			white-space: nowrap;
			text-align: center;
			font-family: Quicksand;
			font-style: normal;
			font-weight: bold;
			font-size: 30px;
			color: rgba(209,209,209,1);
		}
		#region {
			left: 1235px;
			top: 396px;
			position: absolute;
			overflow: visible;
			width: 93px;
			white-space: nowrap;
			text-align: center;
			font-family: Quicksand;
			font-style: normal;
			font-weight: bold;
			font-size: 30px;
			color: rgba(209,209,209,1);
		}
		#city {
			left: 1235px;
			top: 514px;
			position: absolute;
			overflow: visible;
			width: 53px;
			white-space: nowrap;
			text-align: center;
			font-family: Quicksand;
			font-style: normal;
			font-weight: bold;
			font-size: 30px;
			color: rgba(209,209,209,1);
		}
		#declined {
			left: 124px;
			top: 346px;
			position: absolute;
			overflow: visible;
			width: 199px;
			white-space: nowrap;
			text-align: center;
			font-family: Quicksand;
			font-style: normal;
			font-weight: bold;
			font-size: 49px;
			color: rgba(213,213,213,1);
		}
		#agent {
			left: 49px;
			top: 490px;
			position: absolute;
			overflow: visible;
			width: 93px;
			white-space: nowrap;
			text-align: center;
			font-family: Quicksand;
			font-style: normal;
			font-weight: bold;
			font-size: 30px;
			color: rgba(166,166,166,1);
		}
		#reason {
			left: 53px;
			top: 417px;
			position: absolute;
			overflow: visible;
			width: 109px;
			white-space: nowrap;
			text-align: center;
			font-family: Quicksand;
			font-style: normal;
			font-weight: bold;
			font-size: 30px;
			color: rgba(166,166,166,1);
		}
		#Israel {
			left: 1235px;
			top: 310px;
			position: absolute;
			overflow: visible;
			width: 121px;
			white-space: nowrap;
			text-align: center;
			font-family: Quicksand;
			font-style: normal;
			font-weight: bold;
			font-size: 45px;
			color: rgba(22,80,147,1);
		}
		#Coast_line {
			left: 1235px;
			top: 431px;
			position: absolute;
			overflow: visible;
			width: 213px;
			white-space: nowrap;
			text-align: left;
			font-family: Quicksand;
			font-style: normal;
			font-weight: bold;
			font-size: 45px;
			color: rgba(22,80,147,1);
		}
		#Ceasaria {
			left: 1235px;
			top: 543px;
			position: absolute;
			overflow: visible;
			width: 192px;
			white-space: nowrap;
			text-align: left;
			font-family: Quicksand;
			font-style: normal;
			font-weight: bold;
			font-size: 45px;
			color: rgba(22,80,147,1);
		}
		#xdataagent {
			left: 161px;
			top: 487px;
			position: absolute;
			overflow: visible;
			width: 205px;
			white-space: nowrap;
			text-align: center;
			font-family: Quicksand;
			font-style: normal;
			font-weight: bold;
			font-size: 35px;
			color: rgba(22,80,147,1);
		}
		#Last_updated_At {
			left: 49px;
			top: 550px;
			position: absolute;
			overflow: visible;
			width: 201px;
			white-space: nowrap;
			line-height: 27px;
			margin-top: -1px;
			text-align: center;
			font-family: Quicksand;
			font-style: normal;
			font-weight: bold;
			font-size: 25px;
			color: rgba(166,166,166,1);
		}
		#xdataupdatedAt {
			left: 255px;
			top: 550px;
			position: absolute;
			overflow: visible;
			width: 205px;
			white-space: nowrap;
			line-height: 27px;
			margin-top: -1px;
			text-align: center;
			font-family: Quicksand;
			font-style: normal;
			font-weight: bold;
			font-size: 25px;
			color: rgba(22,80,147,1);
		}
		#xdatadeclineReason {
			left: 165px;
			top: 421px;
			position: absolute;
			overflow: visible;
			width: 251px;
			white-space: nowrap;
			line-height: 27px;
			margin-top: -1px;
			text-align: center;
			font-family: Quicksand;
			font-style: normal;
			font-weight: bold;
			font-size: 25px;
			color: rgba(209,209,209,1);
		}
		#VT {
			left: 49px;
			top: 178px;
			position: absolute;
			overflow: visible;
			width: 65px;
			white-space: nowrap;
			text-align: center;
			font-family: Quicksand;
			font-style: normal;
			font-weight: bold;
			font-size: 49px;
			color: rgba(22,80,147,1);
		}
		#SH {
			left: 175px;
			top: 178px;
			position: absolute;
			overflow: visible;
			width: 65px;
			white-space: nowrap;
			text-align: center;
			font-family: Quicksand;
			font-style: normal;
			font-weight: bold;
			font-size: 49px;
			color: rgba(22,80,147,1);
		}
		#TL {
			left: 317px;
			top: 178px;
			position: absolute;
			overflow: visible;
			width: 59px;
			white-space: nowrap;
			text-align: center;
			font-family: Quicksand;
			font-style: normal;
			font-weight: bold;
			font-size: 49px;
			color: rgba(209,209,209,1);
		}
		#Rectangle_672 {
			fill: rgba(255,176,107,1);
			stroke: rgba(112,112,112,1);
			stroke-width: 1px;
			stroke-linejoin: miter;
			stroke-linecap: butt;
			stroke-miterlimit: 4;
			shape-rendering: auto;
		}
		.Rectangle_672 {
			position: absolute;
			overflow: visible;
			width: 53px;
			height: 53px;
			left: 53px;
			top: 251px;
		}
		#Rectangle_675 {
			fill: rgba(255,255,255,1);
			stroke: rgba(112,112,112,1);
			stroke-width: 1px;
			stroke-linejoin: miter;
			stroke-linecap: butt;
			stroke-miterlimit: 4;
			shape-rendering: auto;
		}
		.Rectangle_675 {
			position: absolute;
			overflow: visible;
			width: 53px;
			height: 53px;
			left: 53px;
			top: 354px;
		}
		#Rectangle_673 {
			fill: rgba(254,254,137,1);
			stroke: rgba(112,112,112,1);
			stroke-width: 1px;
			stroke-linejoin: miter;
			stroke-linecap: butt;
			stroke-miterlimit: 4;
			shape-rendering: auto;
		}
		.Rectangle_673 {
			position: absolute;
			overflow: visible;
			width: 53px;
			height: 53px;
			left: 188px;
			top: 251px;
		}
		#Rectangle_674 {
			fill: rgba(255,107,250,1);
			stroke: rgba(112,112,112,1);
			stroke-width: 1px;
			stroke-linejoin: miter;
			stroke-linecap: butt;
			stroke-miterlimit: 4;
			shape-rendering: auto;
		}
		.Rectangle_674 {
			position: absolute;
			overflow: visible;
			width: 53px;
			height: 53px;
			left: 322px;
			top: 251px;
		}
		#V_VT {
			fill: rgba(22,80,147,1);
		}		
		#V_HOOVER {
			fill: rgb(4, 43, 88);
		}
		.V_VT {
			overflow: visible;
			position: absolute;
			width: 64.652px;
			height: 63.95px;
			left: 36.348px;
			top: 230.35px;
			transform: matrix(1,0,0,1,0,0);
		}
		#V_SH {
			fill: rgba(22,80,147,1);
		}
		.V_SH {
			overflow: visible;
			position: absolute;
			width: 64.652px;
			height: 63.95px;
			left: 160.348px;
			top: 230.35px;
			transform: matrix(1,0,0,1,0,0);
		}
		#V_TL {
			fill: rgba(22,80,147,1);
		}		
		.V_TL {
			overflow: visible;
			position: absolute;
			width: 64.652px;
			height: 63.95px;
			left: 292.348px;
			top: 230.35px;
			transform: matrix(1,0,0,1,0,0);
		}
		#Group_1589 {
			position: absolute;
			width: 140px;
			height: 130.633px;
			left: 1324.154px;
			top: 690px;
			overflow: visible;
		}
		#Calendar {
			left: 0px;
			top: 0px;
			position: absolute;
			overflow: visible;
			width: 141px;
			white-space: nowrap;
			text-align: left;
			font-family: Quicksand;
			font-style: normal;
			font-weight: bold;
			font-size: 32px;
			color: rgba(22,80,147,1);
		}
		#When_icon {
			position: absolute;
			width: 74.035px;
			height: 83.063px;
			left: 3.844px;
			top: 47.57px;
			overflow: visible;
		}
		#Path_3010 {
			fill: rgba(44,72,97,1);
		}
		.Path_3010 {
			overflow: visible;
			position: absolute;
			width: 74.035px;
			height: 83.063px;
			left: 0px;
			top: 0px;
			transform: matrix(1,0,0,1,0,0);
		}
		#family {
			position: absolute;
			width: 45.331px;
			height: 55.218px;
			left: 585.266px;
			top: 762.711px;
			overflow: visible;
		}
		#Group_253 {
			position: absolute;
			width: 45.331px;
			height: 55.218px;
			left: 0px;
			top: 0px;
			overflow: visible;
		}
		#Path_2914 {
			fill: rgba(27,156,93,1);
		}
		.Path_2914 {
			overflow: visible;
			position: absolute;
			width: 11.273px;
			height: 11.275px;
			left: 7.998px;
			top: 0px;
			transform: matrix(1,0,0,1,0,0);
		}
		#Path_2915 {
			fill: rgba(27,156,93,1);
		}
		.Path_2915 {
			overflow: visible;
			position: absolute;
			width: 11.275px;
			height: 11.275px;
			left: 27.684px;
			top: 0px;
			transform: matrix(1,0,0,1,0,0);
		}
		#Path_2916 {
			fill: rgba(27,156,93,1);
		}
		.Path_2916 {
			overflow: visible;
			position: absolute;
			width: 45.332px;
			height: 42.978px;
			left: 0px;
			top: 12.241px;
			transform: matrix(1,0,0,1,0,0);
		}
		#Group_368 {
			position: absolute;
			width: 82.491px;
			height: 82.491px;
			left: 684.092px;
			top: 749.974px;
			overflow: visible;
		}
		#Ellipse_17 {
			fill: transparent;
			stroke: rgba(231,231,231,1);
			stroke-width: 2px;
			stroke-linejoin: miter;
			stroke-linecap: butt;
			stroke-miterlimit: 4;
			shape-rendering: auto;
		}
		.Ellipse_17 {
			position: absolute;
			overflow: visible;
			width: 82.49px;
			height: 82.491px;
			left: 0px;
			top: 0px;
		}
		#g2414 {
			position: absolute;
			width: 53.268px;
			height: 50.502px;
			left: 14.557px;
			top: 15.77px;
			overflow: visible;
		}
		#g2416 {
			position: absolute;
			width: 53.268px;
			height: 50.501px;
			left: 0px;
			top: 0px;
			overflow: visible;
		}
		#Group_1161 {
			position: absolute;
			width: 82.491px;
			height: 82.491px;
			left: 787.51px;
			top: 749.367px;
			overflow: visible;
		}
		#Ellipse_18 {
			fill: rgba(255,255,255,1);
			stroke: rgba(231,231,231,1);
			stroke-width: 2px;
			stroke-linejoin: miter;
			stroke-linecap: butt;
			stroke-miterlimit: 4;
			shape-rendering: auto;
		}
		.Ellipse_18 {
			position: absolute;
			overflow: visible;
			width: 82.49px;
			height: 82.491px;
			left: 0px;
			top: 0px;
		}
		#Group_360 {
			position: absolute;
			width: 47.635px;
			height: 41.645px;
			left: 16.982px;
			top: 20.623px;
			overflow: visible;
		}
		#plant {
			position: absolute;
			width: 47.635px;
			height: 41.645px;
			left: 0px;
			top: 0px;
			overflow: visible;
		}
		#Group_248 {
			position: absolute;
			width: 21.089px;
			height: 21.088px;
			left: 0px;
			top: 0px;
			overflow: visible;
		}
		#Group_247 {
			position: absolute;
			width: 21.089px;
			height: 21.088px;
			left: 0px;
			top: 0px;
			overflow: visible;
		}
		#Path_2911 {
			fill: rgba(89,89,89,1);
		}
		.Path_2911 {
			overflow: visible;
			position: absolute;
			width: 21.09px;
			height: 21.088px;
			left: 0px;
			top: 0px;
			transform: matrix(1,0,0,1,0,0);
		}
		#Group_250 {
			position: absolute;
			width: 21.089px;
			height: 21.086px;
			left: 26.547px;
			top: 4.159px;
			overflow: visible;
		}
		#Group_249 {
			position: absolute;
			width: 21.089px;
			height: 21.086px;
			left: 0px;
			top: 0px;
			overflow: visible;
		}
		#Path_2912 {
			fill: rgba(89,89,89,1);
		}
		.Path_2912 {
			overflow: visible;
			position: absolute;
			width: 21.09px;
			height: 21.086px;
			left: -0.002px;
			top: 0px;
			transform: matrix(1,0,0,1,0,0);
		}
		#Group_252 {
			position: absolute;
			width: 29.373px;
			height: 33.642px;
			left: 9.07px;
			top: 8.003px;
			overflow: visible;
		}
		#Group_251 {
			position: absolute;
			width: 29.373px;
			height: 33.642px;
			left: 0px;
			top: 0px;
			overflow: visible;
		}
		#Path_2913 {
			fill: rgba(89,89,89,1);
		}
		.Path_2913 {
			overflow: visible;
			position: absolute;
			width: 29.373px;
			height: 33.642px;
			left: 0px;
			top: 0px;
			transform: matrix(1,0,0,1,0,0);
		}
		#Rectangle_695 {
			fill: rgba(229,229,229,1);
			stroke: rgba(112,112,112,1);
			stroke-width: 1px;
			stroke-linejoin: miter;
			stroke-linecap: butt;
			stroke-miterlimit: 4;
			shape-rendering: auto;
		}
		.Rectangle_695 {
			position: absolute;
			overflow: visible;
			width: 262px;
			height: 64px;
			left: 1225px;
			top: 192px;
		}
		#Rectangle_696 {
			fill: rgba(27,156,93,1);
			stroke: rgba(112,112,112,1);
			stroke-width: 1px;
			stroke-linejoin: miter;
			stroke-linecap: butt;
			stroke-miterlimit: 4;
			shape-rendering: auto;
		}
		.Rectangle_696 {
			position: absolute;
			overflow: visible;
			width: 262px;
			height: 64px;
			left: 1225px;
			top: 116px;
		}
		#SAVE {
			left: 1302px;
			top: 114px;
			position: absolute;
			overflow: visible;
			width: 109px;
			white-space: nowrap;
			text-align: center;
			font-family: Quicksand;
			font-style: normal;
			font-weight: bold;
			font-size: 45px;
			color: rgba(253,253,253,1);
		}
		#Cancel {
			left: 1284px;
			top: 192px;
			position: absolute;
			overflow: visible;
			width: 145px;
			white-space: nowrap;
			text-align: center;
			font-family: Quicksand;
			font-style: normal;
			font-weight: bold;
			font-size: 45px;
			color: rgba(22,80,147,1);
		}
		
	}

	.edit-property-main-top {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.edit-property-main-picture-container {
			

			.edit-property-main-picture {
				width: 700px;
				height: 470px;
				left: 399px;
				top: 100px;
				position: relative;
			}

			.edit-property-page-prev-next-pic {
				padding: 8px;
			}

			.edit-property-main-picture-bullets {
				display: flex;
				align-items: center;
			}

			.edit-property-main-picture-icons {
				display:grid;
				grid-template-columns: 50px 50px 50px 50px 50px;
				align-self: flex-end;
				justify-content: flex-end;

				.edit-property-main-picture-icon {
					padding: 5px;
					cursor: pointer;
				}
			}
		}
	}

	.property-page-body-top {
		padding: 0 40px;
		display: grid;
		grid-template-columns: 1fr 400px;

		.property-page-body-top-left {

			.property-page-body-top-left-info {

				.property-page-body-top-left-info-amenities {
					display: grid;
					grid-template-columns: 1fr 1fr 1fr;

					.property-page-body-top-left-info-amenity {
						display: list-item;
						margin: 5px 18px;
					}
				}

				.property-page-body-top-left-info-amount {
					display: flex;
					color: #AAAAAA;
					flex-direction: column;
					width: 40px;
					align-items: center;
					margin-right: 60px;
					font-size: 22px;
					white-space: nowrap;
				}
			}

			.property-page-body-top-left-info > * {
				color: #284768;
			}

			.property-page-body-top-title {
				font-size: 40px;
				color: #284866;
			}

			.property-page-body-top-subtitle {
				font-size: 30px;
				color: #284866;
			}
		}

		.property-page-body-top-right {

			display: flex;
			flex-direction: column;
			align-items: center;

			.property-page-body-top-dates {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
			}

			.property-page-body-top-price {
				font-size: 48px;
				color: #284866;
			}

			.property-page-body-top-night {
				font-size: 30px;
				color: #284866;
				padding: 10px;
			}

			.property-page-body-top-commission-price {
				font-size: 28px;
				color: #284866;
			}

			.property-page-body-top-commission-title {
				font-size: 22px;
				color: #284866;
			}

			.property-page-body-top-date-title {
				font-size: 18px;
				color: #284866;
			}
		}
	}
}